import { Link } from 'gatsby';
import React from 'react';
import CommonBanner from '../components/banner/CommonBanner';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';

const ApAr = () => {
  return (
    <Layout>
      <Seo
        title="AP AR Automation | Streamline your payments workflow"
        description="Automate the accounts payable processes with banco's AP AR tool. Free up your team to focus their efforts on strategic operations that leads to business growth."
      />
      <main className="main-wrapper">
        <HeaderCommon />

        {/* Deep-tier SCF */}
        <CommonBanner>
          <div className="ap-wrap">
            <div className="content-1">
              <div className="-left">
                <p className="-title">Streamline your AP/ AR workflow</p>
                <p className="-description">
                  Automate the accounts payable processes with banco’s AP/AR tool. This frees up
                  your team to focus their efforts on strategic operations that leads to business
                  growth.
                </p>
                <p className="-description">Decisions that really matters for your organisation!</p>
                <div className="row">
                  <div className="col-6">
                    <Link className="banco-btn btn-fill-primary btn-fluid" to="/contact">
                      Speak with our team
                    </Link>
                  </div>
                </div>
              </div>
              <div className="-right-mobile apar-mobile">
                <div className="card card1">
                  <p className="-title">Receive</p>
                  <p className="-description">
                    Easy to import your invoices with no to little manual entry required, to start
                    the payment process.
                  </p>
                </div>
                <img
                  className="light-mode line1"
                  src="/images/line-img-mobile-1.png"
                  style={{ width: 243 }}
                />
                <div className="card card2">
                  <p className="-title">Approve</p>
                  <p className="-description">
                    Approval workflows can be tailored to fit your company policies. Minimal
                    disruption to current process, just better.
                  </p>
                </div>
                <img
                  className="light-mode line2"
                  src="/images/line-img-mobile-2.png"
                  style={{ width: 266 }}
                />
                <div className="card card3">
                  <p className="-title">Sync</p>
                  <p className="-description">
                    Data (suppliers, invoices, PO, delivery) is simultaneously sync between banco
                    and your accounting software. Seamless operation.
                  </p>
                </div>
                <img
                  className="light-mode line3"
                  src="/images/line-img-mobile-3.png"
                  style={{ width: 243 }}
                />
                <div className="card card4">
                  <p className="-title">Audit</p>
                  <p className="-description">
                    All activities including approvals are tracked and logged digitally on banco for
                    easy referencing for audit and compliance purposes.
                  </p>
                </div>
              </div>
              <div className="-right">
                <img
                  className="light-mode line1"
                  src="/images/line-img-1.png"
                  style={{ width: 470 }}
                />
                <img
                  className="light-mode line2"
                  src="/images/line-img-2.png"
                  style={{ width: 363 }}
                />
                <img
                  className="light-mode line3"
                  src="/images/line-img-3.png"
                  style={{ width: 377 }}
                />
                <div className="card card1">
                  <p className="-title">Receive</p>
                  <p className="-description">
                    Easy to import your invoices with no to little manual entry required, to start
                    the payment process.
                  </p>
                </div>
                <div className="card card2">
                  <p className="-title">Approve</p>
                  <p className="-description">
                    Approval workflows can be tailored to fit your company policies. Minimal
                    disruption to current process, just better.
                  </p>
                </div>
                <div className="card card3">
                  <p className="-title">Sync</p>
                  <p className="-description">
                    Data (suppliers, invoices, PO, delivery) is simultaneously sync between banco
                    and your accounting software. Seamless operation.
                  </p>
                </div>
                <div className="card card4">
                  <p className="-title">Audit</p>
                  <p className="-description">
                    All activities including approvals are tracked and logged digitally on banco for
                    easy referencing for audit and compliance purposes.
                  </p>
                </div>
              </div>
            </div>
            <p className="-breadcrumb">
              Improve business efficiency with our banco AP/AR automation
            </p>
            <div className="row content-2">
              <div className="card col-4">
                <div>
                  <img className="light-mode" src="/images/icon-real.png" />
                </div>
                <div>
                  <p className="-title">Real time visibility and transparency</p>
                  <p className="-description">
                    Get a bird’s eye view of your accounts throughout the entire invoice processing.
                  </p>
                </div>
              </div>
              <div className="card col-4">
                <div>
                  <img className="light-mode" src="/images/icon-seamless.png" />
                </div>
                <div>
                  <p className="-title">Seamless operation with your enterprise systems</p>
                  <p className="-description">
                    banco expands your operational capabilities by integrating with your current
                    enterprises systems (Procurement, ERP, Accounting). Working as one system.
                  </p>
                </div>
              </div>
              <div className="card col-4">
                <div>
                  <img className="light-mode" src="/images/icon-accelerate.png" />
                </div>
                <div>
                  <p className="-title">Accelerate your invoice processing cycle</p>
                  <p className="-description">
                    Reduce manual work, delays and time spent on exceptional handling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CommonBanner>

        <Footer />
      </main>
    </Layout>
  );
};

export default ApAr;
